import React from 'react';
// import HomeBanner from '../../components/HomePage/HomeBanner';
import Carousel from '../../components/HomePage/Carousel';
import TwoColumn from '../../components/HomePage/TwoColumn';
import Reviews from './../../components/Reviews';


const Homepage = props => {
  window.scrollTo(0, 0);
  return (
    <section className="homepage">
      {/* <HomeBanner /> */}
      <Carousel />
      <TwoColumn/>

      <Reviews/>

    </section>
  );
};

export default Homepage;