import React from 'react';
// import { useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
// import { clearCart } from './../../redux/Cart/cart.actions';
// import LRL_BG from './../../assets/HomePage/LRL_Background.jpg';

import './styles.scss';

const Footer = props => {
  // const dispatch = useDispatch();
  // const handleRemoveAllCartItem = () => {
  //   dispatch(
  //     clearCart()
  //   );
  // }

  return (
    <footer className="footer">

        <Grid container spacing={0} className="footerDesc footerbg"> 
            <Grid item xs={8} sm={4} className="footerNH" >
                  <div className="footerTitle">
                      Need Help?
                  </div>
                    <ul>
                        <li>
                          <a href="mailto:trophyhouseindore@gmail.com">
                            <i className="fas fa-at faicon"></i>
                            trophyhouseindore@gmail.com
                          </a>
                        </li>
                        <li>
                          <a href="https://wa.me/0919754500000?text=I'm%20interested%20in%20your%20Item" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-whatsapp faicon"></i>
                            +91 97545 00000
                          </a>
                        </li>
                        <li>
                          <a href="https://wa.me/0918085577007?text=I'm%20interested%20in%20your%20Item" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-whatsapp faicon"></i>
                            +91 80855 77007
                          </a>
                        </li>
                        {/* <li>
                          <a href="https://maps.app.goo.gl/SkWggTePK5E725uL9" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-map-marker faicon"></i>
                            166 Usha Nagar Extn., Indore (MP)
                          </a>
                        </li>
                        <li>
                          <a href="https://maps.app.goo.gl/SkWggTePK5E725uL9" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-map-marker faicon"></i>
                            73 Jawahar Marg, Nand Lal Pura Square, Indore (MP)
                          </a>
                        </li> */}
                        
                        {/* <li>
                          <Link  target={"_blank"} rel="noreferrer" to="/size-guide">
                            <i className="fas fa-ruler-horizontal faicon faiconruler"></i>
                            Size Guide
                          </Link>
                        </li> */}
                        {/* <li>
                          <a href="https://www.madeformefashion.com/" className="cartBtn remove" onClick={() => handleRemoveAllCartItem()}>
                            <i className="fas fa-sync-alt faicon"></i>
                            Refresh Cart
                          </a>
                        </li> */}
                  </ul>
            </Grid>
            
            <Grid item xs={4} sm={5} className="footerInfo">
              
                  <div className="footerTitle">
                      FACTORY
                  </div>
                      <ul>
                        {/* <li>
                          <Link to="/shipping-policy">
                            Shipping Policy
                          </Link>
                        </li>
                        <li>
                          <Link to="/return-policy">
                            Return Policy
                          </Link>
                        </li> */}
                        {/* <p> */}
                          BHARAT TRADING COMPANY
                        {/* </p> */}
                        <p></p>
                        <li>
                          <a href="https://maps.app.goo.gl/tLgtceWsGy5EYvLy6" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-map-marker faicon"></i>
                            149, Sector D, Sanwer Road Industrial Area, Indore (MP)
                          </a>
                        </li>
                        {/* <li>
                          <Link to="/terms-of-service">
                            Terms of Service
                          </Link>
                        </li>
                        <li>
                          <Link to="/about-us">
                            About Us
                          </Link>
                        </li> */}
                      </ul>

            </Grid>

            {/* <Grid item xs={12} sm={3} className="footerSocial">
                  <div className="footerTitle">
                      Follow us:
                  </div>
                      <ul>
                        <li>
                            <a target={"_blank"} rel="noreferrer" href="https://www.instagram.com/madeformefashion" aria-label="instagram"> 
                              <i className="fab fa-instagram fa-2x faicon faiconSocial"></i>
                            </a>

                            <a target={"_blank"} rel="noreferrer" href="https://www.facebook.com/madeformefashion/" aria-label="facebook">
                              <i className="fab fa-facebook-f fa-2x faicon faiconSocial"></i>
                            </a>

                            <a target={"_blank"} rel="noreferrer" href="https://www.youtube.com/channel/UC5cUygdAyAtvMtfCas0hRQw" aria-label="youtube">
                              <i className="fab fa-youtube fa-2x faicon faiconSocial"></i>
                            </a>
                        </li>
                      </ul>
            </Grid> */}

          </Grid>

          


      <div className="footerCopy">
        <Grid container spacing={1} > 
          <Grid item xs={12} sm={8} className="footercop">
              ©{new Date().getFullYear()} All Rights reserved by Trophy House.
          </Grid>

          <Grid item xs={12} sm={4} className="footerCreatedBy">
                Website Developed by EdenCoders
                  <a target={"_blank"} rel="noreferrer" href="https://www.linkedin.com/in/manish-chandwaney" aria-label="linkedin"> <i className="fab fa-linkedin"></i></a>
                  {/* <a target={"_blank"} rel="noreferrer" href="https://www.facebook.com/profile.php/?id=100000018767222" aria-label="facebook"> <i className="fab fa-facebook-f"></i></a> */}
          </Grid>
        </Grid>

        </div>
    </footer>
  );
}

export default Footer;