import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
// import { Link } from 'react-router-dom';
// import Button from './../../forms/Button';
import './styles.scss';


import Acrylic from '../../../assets/Catalogue/Acrylic_Catalogue_2024.pdf';
import Badge from '../../../assets/Catalogue/Badges_Catalogue_2024.pdf';
import Crystal from '../../../assets/Catalogue/Crystal_Catalogue_2024.pdf';
import Gift from '../../../assets/Catalogue/Gift_Items_Catalogue_2024.pdf';
import Medal from '../../../assets/Catalogue/Medals_Catalogue_2024.pdf';
import Trophy from '../../../assets/Catalogue/Trophies_Catalogue_2024.pdf';

// https://firebasestorage.googleapis.com/v0/b/trophyhouseindore-2d86c.appspot.com/o/Acrylic%20Catalogue%202023.pdf?alt=media&token=32908d5d-0de0-4cfc-bfa3-ef4f7039f2bb


const LRL1 = 'https://ik.imagekit.io/trophyhouseindore/TROPHY.jpg';
// const LRL1 = 'https://ik.imagekit.io/madeformefashion/HomePage/tr:w-800/LRL1.jpg';
const LRL2 = 'https://ik.imagekit.io/trophyhouseindore/ACRYLIC.jpg';

// https://ik.imagekit.io/trophyhouseindore/GIFTS.jpg?updatedAt=1715066225226?

const LRL3 = 'https://ik.imagekit.io/trophyhouseindore/GIFTS.jpg';



const LRL4 = 'https://ik.imagekit.io/trophyhouseindore/MEDAL.jpg';
const LRL5 = 'https://ik.imagekit.io/trophyhouseindore/BADGE.jpg';
const LRL6 = 'https://ik.imagekit.io/trophyhouseindore/CRYSTAL.jpg';

// const LRL_BG = 'https://ik.imagekit.io/madeformefashion/HomePage/LRL_Background.jpg';







// const background = {
//     backgroundImage: `url(${LRL_BG})`,
// };

//  const configAddToCartBtn = {
//       type: 'button'
//   }


const TwoColumn = () => {
    // const history = useHistory();
    // const dispatch = useDispatch();
    // const { products } = useSelector(mapState);
    // const { data } = products;
    // let documentID = '';

    // console.log("Data", data)

    // <Grid container spacing={0} className="LRL LRL1" style={background} > 

    return(

        <div>

            <Grid container spacing={0} className="LRL LRL1" > 
                <Grid item xs={12} sm={6} className="TWRight">
                    <div className="img">
                        <img src={LRL1} alt="Logo" />
                    </div>
                </Grid>

                <Grid item xs={12} sm={3} className="TWLeft" >
                    <div className="TWdesc">
                            <h4>
                            Exquisite trophies embodying excellence in craftsmanship and achievement.
                            </h4>

                            <a href={Trophy} target="_blank" rel="noreferrer">
                                <div  className="LRLbutton" >
                                    Trophy Catalogue
                                </div>
                            </a>
                            
                    </div>
                </Grid>
                
            </Grid>



            <Grid container spacing={0} direction="row-reverse" className="LRL LRL2"   justifyContent="center">
                
                <Grid item xs={12} sm={3} className="TWRight">
                    <div className="img">
                        <img src={LRL2} alt="Logo" />
                    </div>
                </Grid>
                <Grid item xs={12} sm={3} className="TWLeft" >
                    <div className="TWdesc TWdescLeft">
                            <h4>
                            Elevate your recognition with our stunning acrylic trophies
                            </h4>

                            <a href={Acrylic} target="_blank" rel="noreferrer">
                                <div  className="LRLbutton" >
                                    Acrylic Catalogue
                                </div>
                            </a>

                    </div>
                </Grid>
            </Grid>


            <Grid container spacing={0} className="LRL LRL1" >
                <Grid item xs={12} sm={6} className="TWRight">
                    <div className="img">
                        <img src={LRL3} alt="Logo" />
                    </div>
                </Grid>
                <Grid item xs={12} sm={3} className="TWLeft" >
                    <div className="TWdesc">
                            <h4>
                                From timeless classics to modern marvels, discover the perfect gifts for every occasion.
                            </h4>

                            <a href={Gift} target="_blank" rel="noreferrer">
                                <div  className="LRLbutton" >
                                    Gift Items Catalogue
                                </div>
                            </a>

                    </div>
                </Grid>
            </Grid>


            <Grid container spacing={0} direction="row-reverse" className="LRL LRL4"   justifyContent="center">
                
                <Grid item xs={12} sm={3} className="TWRight">
                    <div className="img">
                        <img src={LRL4} alt="Logo" />
                    </div>
                </Grid>
                <Grid item xs={12} sm={3} className="TWLeft" >
                    <div className="TWdesc TWdescLeft">
                            <h4>
                                Embrace the spirit of victory with our meticulously crafted medals.
                            </h4>

                            <a href={Medal} target="_blank" rel="noreferrer">
                                <div  className="LRLbutton" >
                                    Medals Catalogue
                                </div>
                            </a>
                    </div>
                </Grid>
            </Grid> 
            
             
            <Grid container spacing={0} className="LRL LRL1" >
                <Grid item xs={12} sm={6} className="TWRight">
                    <div className="img">
                        <img src={LRL5} alt="Logo" />
                    </div>
                </Grid>
                <Grid item xs={12} sm={3} className="TWLeft" >
                    <div className="TWdesc">
                            <h4>
                            From sleek and minimalist designs to vibrant and eye-catching styles, our collection offers badges to suit every needs.
                            </h4>

                            <a href={Badge} target="_blank" rel="noreferrer">
                                <div  className="LRLbutton" >
                                    Badges Catalogue
                                </div>
                            </a>

                    </div>
                </Grid>
            </Grid>


            <Grid container spacing={0} direction="row-reverse" className="LRL LRL4"   justifyContent="center">
                
                <Grid item xs={12} sm={3} className="TWRight">
                    <div className="img">
                        <img src={LRL6} alt="Logo" />
                    </div>
                </Grid>
                <Grid item xs={12} sm={3} className="TWLeft" >
                    <div className="TWdesc TWdescLeft">
                            <h4>
                                Captivate with brilliance and elegance - explore our exquisite collection of crystal creations
                            </h4>

                            <a href={Crystal} target="_blank" rel="noreferrer">
                                <div  className="LRLbutton" >
                                    Crystal Catalogue
                                </div>
                            </a>

                    </div>
                </Grid>
            </Grid>

        </div>
    )
}

export default TwoColumn;