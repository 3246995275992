import React from 'react';
import Checkout from './../../components/Checkout';

const Cart = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <Checkout />
    </div>
  );
}

export default Cart;
