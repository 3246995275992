import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { fetchProductStart, setProduct } from './../../redux/Products/products.actions';
import { sizeChange } from './../../redux/Cart/cart.actions';
import { discountPerc } from '../../Utils';



import CompleteLook from './CompleteLook';
import Sizes from './Sizes';
import Images from './Images';
import Slides from './Slides';
import DescriptionBox from './DescriptionBox';



import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import './styles.scss';



const mapState = state => ({
  product: state.productsData.product,
  size: state.productsData.product,
});


const ProductCard = () => {
  const dispatch = useDispatch();
  const { productID } = useParams();
  const { product } = useSelector(mapState);

  const {
    productName,
    productPrice,
    subProdCategory,
  } = product;


  const DiscPrice = (Number(productPrice) * (100-discountPerc)/100).toFixed(2);

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(
      fetchProductStart(productID)
    )
    dispatch(
      sizeChange('size1'),
    );
    
    
    return () => {
      dispatch(
        setProduct({})
        )
      }
      
  }, [dispatch, productID]);

      
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
  }));

  const classes = useStyles();


  return (

    <div className={classes.root}>
      <div className="productCard">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={9}>
            <div className="hero">
              <Images {...product} />
            </div>

          </Grid>


          <Grid item xs={12} sm={3}>
            <div className="productDetails">
              <ul>
                <li>
                  {(discountPerc !== 0) &&
                    <h3 className="discDesc">
                      {discountPerc}% off
                    </h3>
                  }
                  <h1>
                    {productName}
                  </h1>
                </li>

                { (discountPerc === 0) ? 
                  <li>
                    <span className="price noOffer">
                      £{productPrice}
                    </span>
                  </li>
                  :
                  <li>
                    <span className="price">
                      <span className="orgPrice">
                        £{productPrice}
                      </span>
                      £{DiscPrice}
                    </span>
                  </li>
                }

                <div className="sizes">
                  <h4>
                    {
                    (subProdCategory === 'hairband') ? 
                      <span>
                        Colours:
                      </span>
                      :
                      <span>
                        Sizes:
                      </span>
                    }
                    <span className="sizeChart">
                      <Link target={"_blank"} to="/size-guide">
                          Size Chart
                      </Link>
                    </span>
                  </h4> 
                  

                 <Sizes {...product} />

                </div>

              <li>

                <DescriptionBox />
              </li>



               

              </ul>
            </div>

            <div className ="complete-look"> 

              <span>
                  <h4>
                    Complete the look:
                  </h4>
              </span>
              <CompleteLook />

            </div>
          
          </Grid>
        </Grid>
        


      </div>
        <Grid item xs={12} sm={12} className="Slides">
          <Slides />
        </Grid>
    </div>
    

  );
}

export default ProductCard;
