import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct, sizeChange } from '../../../redux/Cart/cart.actions';
import { fetchProductsStart } from '../../../redux/Products/products.actions';
import { selectCartOnlyItems} from './../../../redux/Cart/cart.selectors';
import './styles.scss';
import Button from './../../forms/Button';
import { createStructuredSelector } from 'reselect';
import Quantity from './../Quantity';


const mapState = ({ productsData }) => ({
    products: productsData.products
  });

const mapStateCart = createStructuredSelector({
  cartItems: selectCartOnlyItems,
  });

const dressSizeNames = ["3-6M", "6-12M", "1-2Y", "2-3Y", "3-4Y", "5-6Y", "6-7Y", "7-8Y", "8-9Y", "10-11Y", "12-13Y"];
const shoesSizeNames = ["4.5", "5", "5.5", "6", "7", "7.5", "8.5", "9", "10", "10.5", "11", "11.5", "12"];
const hairbandSizeNames = ["ONE SIZE"];
const othersSizeNames = ["ONE SIZE"];

const Sizes = (product) => {
    const { products } = useSelector(mapState);
    const dispatch = useDispatch();
    const history = useHistory();
    const { cartItems } = useSelector(mapStateCart);
    let size1 = '';
    let size2 = '';
    let size3 = '';
    let size4 = '';
    let size5 = '';
    let size6 = '';
    let size7 = '';
    let size8 = '';
    let size9 = '';
    let size10 = '';
    let size11 = '';
    let size12 = '';
    let size13 = '';
    let prodCategory = '';
    let subProdCategory = '';

    const cartItemsnew = cartItems.filter(currentItem => currentItem.documentID === product.documentID);
    
    const [showAddtoCart, setShowAddtoCart] = useState(false)
    const [showLastOrder, setShowLastOrder] = useState(false)

    let {
        productName
      } = product;


    const { data } = products;

  useEffect(() => {
    
    window.scrollTo(0, 0)
    dispatch(
      fetchProductsStart()
    );
  }, [dispatch]);

  if(typeof data != 'undefined') {

    const currProduct = data.filter(
      products => products.productName === productName
    )[0];

    if(typeof currProduct != 'undefined') {

        const sizeArray = currProduct.productSize; //Available size

        size1 = sizeArray[0];
        size2 = sizeArray[1];
        size3 = sizeArray[2];
        size4 = sizeArray[3];
        size5 = sizeArray[4];
        size6 = sizeArray[5];
        size7 = sizeArray[6];
        size8 = sizeArray[7];
        size9 = sizeArray[8];
        size10 = sizeArray[9];
        size11 = sizeArray[10];
        size12 = sizeArray[11];
        size13 = sizeArray[12];
        
        prodCategory = currProduct.productCategory;
        subProdCategory = currProduct.subProductCategory;
    }

  }


  const handleAddToCart = (product) => {
    

    if (!product) return;

    dispatch(
      addProduct(product)
    );

    dispatch(
        sizeChange('2Y'),
      );

    history.push('/cart');
  }

    const configAddToCartBtn = {
        type: 'button'
    }

  

 
  const handleSizeChange = (options) => {

      const size = options[1];    // size name
      const sizeVal = options[0]; // available size

    if(typeof size !== 'undefined') {

      dispatch(
        sizeChange(size)
      );
  
      setShowAddtoCart(true)

        if(sizeVal === '1') {
          setShowLastOrder(true)
        } else {
          setShowLastOrder(false)
        }

        
    }else {
      setShowAddtoCart(false)
    }


  }



    return (
       
        <div className="outer">

            {prodCategory === 'dresses' ?

              <div className="container">
                  
                <div className="radio-tile-group">

                      <div className="input-container" onClick={() => handleSizeChange(size1 >=1 ? [size1, dressSizeNames[0]] : "")}> {/* size1: available size */}
                          <input id="size1" className="radio-button" type="radio" name="radio"  /> {/* defaultChecked */}
                          <div className={"radio-tile " + (size1 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size1" className={"radio-tile-label " + (size1 >= '1' ? '' : 'disabled')}>
                                {dressSizeNames[0]}
                                </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size2 >=1 ? [size2, dressSizeNames[1]] : "")}>
                          <input id="size2" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size2 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size2" className={"radio-tile-label " + (size2 >= '1' ? '' : 'disabled')}>
                                {dressSizeNames[1]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size3 >=1 ? [size3, dressSizeNames[2]] : "")}>
                          <input id="size3" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size3 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size3" className={"radio-tile-label " + (size3 >= '1' ? '' : 'disabled')}>
                                {dressSizeNames[2]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size4 >=1 ? [size4, dressSizeNames[3]] : "")}>
                          <input id="size4" className="radio-button" type="radio" name="radio"  />
                          <div className={"radio-tile " + (size4 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size4" className={"radio-tile-label " + (size4 >= '1' ? '' : 'disabled')}>
                                {dressSizeNames[3]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size5 >=1 ? [size5, dressSizeNames[4]] : "")}>
                          <input id="size5" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size5 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size5" className={"radio-tile-label " + (size5 >= '1' ? '' : 'disabled')}>
                                {dressSizeNames[4]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size6 >=1 ? [size6, dressSizeNames[5]] : "")}>
                          <input id="size6" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size6 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size6" className={"radio-tile-label " + (size6 >= '1' ? '' : 'disabled')}>
                                {dressSizeNames[5]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size7 >=1 ? [size7, dressSizeNames[6]] : "")}>
                          <input id="size7" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size7 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size7" className={"radio-tile-label " + (size7 >= '1' ? '' : 'disabled')}>
                                {dressSizeNames[6]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size8 >=1 ? [size8, dressSizeNames[7]] : "")}>
                          <input id="size8" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size8 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size8" className={"radio-tile-label " + (size8 >= '1' ? '' : 'disabled')}>
                                {dressSizeNames[7]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size9 >=1 ? [size9, dressSizeNames[8]] : "")}>
                          <input id="size9" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size9 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size9" className={"radio-tile-label " + (size9 >= '1' ? '' : 'disabled')}>
                                {dressSizeNames[8]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size10 >=1 ? [size10, dressSizeNames[9]] : "")}>
                          <input id="size10" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size10 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size10" className={"radio-tile-label " + (size10 >= '1' ? '' : 'disabled')}>
                                {dressSizeNames[9]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size11 >=1 ? [size11, dressSizeNames[10]] : "")}>
                          <input id="size11" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size11 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size11" className={"radio-tile-label " + (size11 >= '1' ? '' : 'disabled')}>
                                {dressSizeNames[10]}
                              </label>
                          </div>
                      </div>
                      
                </div>

              </div>

            :

            subProdCategory === 'shoes' ?

            <div className="container">
                  
                  <div className="radio-tile-group" >

                      <div className="input-container" onClick={() => handleSizeChange(size1 >=1 ? [size1, shoesSizeNames[0]] : "")}>
                        {/* size1: available size */}
                          <input id="size1" className="radio-button" type="radio" name="radio"  /> {/* defaultChecked */}
                          <div className={"radio-tile " + (size1 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size1" className={"radio-tile-label " + (size1 >= '1' ? '' : 'disabled')}>
                                {shoesSizeNames[0]}
                                </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size2 >=1 ? [size2, shoesSizeNames[1]] : "")}>
                          <input id="size2" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size2 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size2" className={"radio-tile-label " + (size2 >= '1' ? '' : 'disabled')}>
                                {shoesSizeNames[1]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size3 >=1 ? [size3, shoesSizeNames[2]] : "")}>
                          <input id="size3" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size3 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size3" className={"radio-tile-label " + (size3 >= '1' ? '' : 'disabled')}>
                                {shoesSizeNames[2]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size4 >=1 ? [size4, shoesSizeNames[3]] : "")}>
                          <input id="size4" className="radio-button" type="radio" name="radio"  />
                          <div className={"radio-tile " + (size4 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size4" className={"radio-tile-label " + (size4 >= '1' ? '' : 'disabled')}>
                                {shoesSizeNames[3]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size5 >=1 ? [size5, shoesSizeNames[4]] : "")}>
                          <input id="size5" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size5 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size5" className={"radio-tile-label " + (size5 >= '1' ? '' : 'disabled')}>
                                {shoesSizeNames[4]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size6 >=1 ? [size6, shoesSizeNames[5]] : "")}>
                          <input id="size6" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size6 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size6" className={"radio-tile-label " + (size6 >= '1' ? '' : 'disabled')}>
                                {shoesSizeNames[5]}
                              </label>
                          </div>
                      </div>
                      
                      <div className="input-container" onClick={() => handleSizeChange(size7 >=1 ? [size7, shoesSizeNames[6]] : "")}>
                          <input id="size7" className="radio-button" type="radio" name="radio"  /> 
                          <div className={"radio-tile " + (size7 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size7" className={"radio-tile-label " + (size7 >= '1' ? '' : 'disabled')}>
                                {shoesSizeNames[6]}
                                </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size8 >=1 ? [size8, shoesSizeNames[7]] : "")}>
                          <input id="size8" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size8 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size8" className={"radio-tile-label " + (size8 >= '1' ? '' : 'disabled')}>
                                {shoesSizeNames[7]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size9 >=1 ? [size9, shoesSizeNames[8]] : "")}>
                          <input id="size9" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size9 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size9" className={"radio-tile-label " + (size9 >= '1' ? '' : 'disabled')}>
                                {shoesSizeNames[8]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size10 >=1 ? [size10, shoesSizeNames[9]] : "")}>
                          <input id="size10" className="radio-button" type="radio" name="radio"  />
                          <div className={"radio-tile " + (size10 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size10" className={"radio-tile-label " + (size10 >= '1' ? '' : 'disabled')}>
                                {shoesSizeNames[9]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size11 >=1 ? [size11, shoesSizeNames[10]] : "")}>
                          <input id="size11" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size11 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size11" className={"radio-tile-label " + (size11 >= '1' ? '' : 'disabled')}>
                                {shoesSizeNames[10]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size12 >=1 ? [size12, shoesSizeNames[11]] : "")}>
                          <input id="size12" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size12 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size12" className={"radio-tile-label " + (size12 >= '1' ? '' : 'disabled')}>
                                {shoesSizeNames[11]}
                              </label>
                          </div>
                      </div>

                      <div className="input-container" onClick={() => handleSizeChange(size13 >=1 ? [size13, shoesSizeNames[12]] : "")}>
                          <input id="size13" className="radio-button" type="radio" name="radio" />
                          <div className={"radio-tile " + (size13 >= '1' ? '' : 'disabled')}>
                              <label htmlFor="size13" className={"radio-tile-label " + (size13 >= '1' ? '' : 'disabled')}>
                                {shoesSizeNames[12]}
                              </label>
                          </div>
                      </div>
                      
                  </div>

              </div>

            :

            subProdCategory === 'hairband' ?

            <div className="container">
                  
              <div className="radio-tile-group">

                    <div className="input-container oneSize" onClick={() => handleSizeChange(size1 >=1 ? [size1, hairbandSizeNames[0]] : "")}> {/* size1: available size */}
                        <input id="size1" className="radio-button" type="radio" name="radio"  /> {/* defaultChecked */}
                        <div className={"radio-tile " + (size1 >= '1' ? '' : 'disabled')}>
                            <label htmlFor="size1" className={"radio-tile-label " + (size1 >= '1' ? '' : 'disabled')}>
                              {hairbandSizeNames[0]}
                              </label>
                        </div>
                    </div>
              </div>

            </div>

            :

            <div className="container">
                  
              <div className="radio-tile-group">

                    <div className="input-container oneSize" onClick={() => handleSizeChange(size1 >=1 ? [size1, othersSizeNames[0]] : "")}> {/* size1: available size */}
                        <input id="size1" className="radio-button" type="radio" name="radio"  /> {/* defaultChecked */}
                        <div className={"radio-tile " + (size1 >= '1' ? '' : 'disabled')}>
                            <label htmlFor="size1" className={"radio-tile-label " + (size1 >= '1' ? '' : 'disabled')}>
                              {othersSizeNames[0]}
                              </label>
                        </div>
                    </div>

              </div>

            </div>
            
            }

            {/* showLastOrder */}

            {showLastOrder ?
              <div className="one" >
                  <h5>
                    *Last piece left.
                  </h5>
              </div> : '' }



                <div className ="input-quantity"> 

                    {cartItemsnew.map((item) => {
                          return (
                            <Quantity key={item} {...item} />
                          )
                    })}
                </div>



                { 
                  showAddtoCart ? 
                  <div className="addToCart">
                      <Button {...configAddToCartBtn} onClick={() => handleAddToCart(product)}>
                        Add to cart
                      </Button>
                  </div>  
                  : 
                  <div className="addToCart">
                    <Button className="btn disabled">
                      Please select a size
                    </Button>
                  </div>
                }

                

        </div>
    );
}

export default Sizes;
