import axios from 'axios';

export const checkUserIsAdmin = currentUser => {
  if (!currentUser || !Array.isArray(currentUser.userRoles)) return false;
  const { userRoles } = currentUser;
  if (userRoles.includes('admin')) return true;

  return false;
}

export const apiInstance = axios.create({
  // baseURL: 'https://europe-west2-madeforme-40d4c.cloudfunctions.net/api'
  
  baseURL: 'https:'
});





export const discountPerc = 30;    //0

export const freeShipping = 20;

export const holiday = false;          
export const holidayDate = '24th December';          // 1 holiday on




