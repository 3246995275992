import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
// import { addProductStart, fetchProductsStart, deleteProductStart } from './../../redux/Products/products.actions';
import { addProductStart, fetchProductsStart } from './../../redux/Products/products.actions';
import { getemailID } from './../../redux/User/user.helpers';
import Modal from './../../components/Modal';
import FormInput from './../../components/forms/FormInput';
import FormSelect from './../../components/forms/FormSelect';
import Button from './../../components/forms/Button';
// import CKEditor from 'ckeditor4-react';
// import productsLink from './productsLink';
import './styles.scss';



// const objectArray = Object.entries(productsLink);


// const mapState = ({ productsData }) => ({
//   products: productsData.products
// });

let ImgOptions = [];

const Admin = props => {
  // const { products } = useSelector(mapState);
  const dispatch = useDispatch();
  const [hideModal, setHideModal] = useState(true);
  const [productCategory, setProductCategory] = useState('dresses');
  const [subProductCategory, setSubProductCategory] = useState('shoes');
  const [productName, setProductName] = useState('');
  // const [productThumbnail, setProductThumbnail] = useState(objectArray[0][1]);
  const [productThumbnail, setProductThumbnail] = useState('');
  const [productPrice, setProductPrice] = useState(0);
  const [productDesc, setProductDesc] = useState('');
  const [productComp, setProductComp] = useState('');
  const [productCare, setProductCare] = useState('');
  // const [productAccessories, setProductAccessories] = useState('');
  const [[...productSize] , setProductSize] = useState([]);
  
  const [productThumbnail2, setProductThumbnail2] = useState('');
  const [productThumbnail3, setProductThumbnail3] = useState('');
  const [productThumbnail4, setProductThumbnail4] = useState('');
  const [productThumbnail5, setProductThumbnail5] = useState('');
  const [productThumbnail6, setProductThumbnail6] = useState('');

  const [youtubeEmbedID, setYoutubeEmbedID] = useState('');
  const [rankforsorting, setrankforsorting] = useState('');
  const [showProduct, setShowProduct] = useState(false);

  // const { data } = products;

  useEffect(() => {
    dispatch(
      fetchProductsStart()
    );
  }, [dispatch]);

  const toggleModal = () => setHideModal(!hideModal);

  const configModal = {
    hideModal,
    toggleModal
  };

  // console.log("Happy");


  const resetForm = () => {
    setHideModal(true);
    setProductCategory('dresses');
    setSubProductCategory('shoes');
    setProductName('');
    // setProductThumbnail(objectArray[0][1]);
    setProductThumbnail('');
    setProductPrice(0);
    setProductDesc('');
    setProductComp('');
    setProductCare('');
    // setProductAccessories('');
    setProductSize('');
    setProductThumbnail2('');
    setProductThumbnail3('');
    setProductThumbnail4('');
    setProductThumbnail5('');
    setProductThumbnail6('');
    setYoutubeEmbedID('');
    setrankforsorting(0);
    setShowProduct(false);
  };

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(
      addProductStart({
        productCategory,
        subProductCategory,
        productName,
        productThumbnail,
        productPrice,
        productDesc,
        productComp,
        productCare,
        // productAccessories,
        productSize,
        productThumbnail2,
        productThumbnail3,
        productThumbnail4,
        productThumbnail5,
        productThumbnail6,
        youtubeEmbedID,
        showProduct,
        rankforsorting,
      })
    );
    resetForm();

  };


  

  // objectArray.forEach((value, index) => {
    
  //   ImgOptions[index] = {
  //     value: value[1],
  //     name: value[0],
  //     key:index
  //   }
  // })

  return (
    <div className="admin">


      <div className="email callToActions">
        <ul>
          <li>
            <Button onClick={() => getemailID()}>
              Get email
            </Button>
          </li>
        </ul>
      </div>

      <div className="callToActions">
        <ul>
          <li>
            <Button onClick={() => toggleModal()}>
              Add new product
            </Button>
          </li>
        </ul>
      </div>

      <Modal {...configModal}>
        <div className="addNewProductForm">
          <form onSubmit={handleSubmit}>

            <h2>
              Add new product
            </h2>

            <FormSelect
              label="Category"
              options={[{
                value: "dresses",
                name: "Dressses",
                key: 1
              }, {
                value: "accessories",
                name: "Accessories",
                key: 2
              }]}
              handleChange={e => setProductCategory(e.target.value)}
            />

            <FormSelect
              label="Sub Category"
              options={[{
                value: "shoes",
                name: "shoes",
                key: 1
              }, {
                value: "hairband",
                name: "hairband",
                key: 2
              }, {
                value: "others",
                name: "others",
                key: 3
              },
            ]}
              handleChange={e => setSubProductCategory(e.target.value)}
            />

            <FormInput
              label="Name"
              type="text"
              value={productName}
              handleChange={e => setProductName(e.target.value)}
            />


            <br/><br/><br/>

            <FormSelect
              label="Image URL"
              options={ImgOptions}
              handleChange={e => setProductThumbnail(e.target.value)}
            />

            <FormSelect
              label="Image 2"
              options={ImgOptions}
              handleChange={e => setProductThumbnail2(e.target.value)}
            />

            <FormSelect
              label="Image 3"
              options={ImgOptions}
              handleChange={e => setProductThumbnail3(e.target.value)}
            />

            <FormSelect
              label="Image 4"
              options={ImgOptions}
              handleChange={e => setProductThumbnail4(e.target.value)}
            />

            <FormSelect
              label="Image 5"
              options={ImgOptions}
              handleChange={e => setProductThumbnail5(e.target.value)}
            />
            
            <FormSelect
              label="Image 6"
              options={ImgOptions}
              handleChange={e => setProductThumbnail6(e.target.value)}
            />


            <FormInput
              label="Price"
              type="number"
              min="0.00"
              max="10000.00"
              step="0.01"
              value={productPrice}
              handleChange={e => setProductPrice(e.target.value)}
            />

            <br/><br/><br/>

            {/* <CKEditor
              onChange={evt => setProductDesc(evt.editor.getData())}
            /> */}

            <FormInput
              label="Description"
              type="text"
              value={productDesc}
              handleChange={e => setProductDesc(e.target.value)}
            />

            <br/><br/><br/>

            <FormInput
              label="Composition"
              type="text"
              value={productComp}
              handleChange={e => setProductComp(e.target.value)}
            />

            <br/><br/><br/>

            <FormInput
              label="Wash Care"
              type="text"
              value={productCare}
              handleChange={e => setProductCare(e.target.value)}
            />

            <br/><br/><br/>

            <FormInput
              label="Sizes"
              type="text"
              value={
                productSize
              }
              handleChange={e => setProductSize(e.target.value)}
            />


            <br/><br/><br/>

            <FormInput
              label="YoutubeEmbedID"
              type="text"
              value={
                youtubeEmbedID
              }
              handleChange={e => setYoutubeEmbedID(e.target.value)}
            />

            <br/><br/><br/>

            <FormInput
              label="rankforsorting"
              type="number"
              value={
                rankforsorting
              }
              handleChange={e => setrankforsorting(e.target.value)}
            />


            <br/><br/><br/>


            <FormSelect
              label="showProduct"
              type="boolean"
              options={[{
                value: true,
                name: "true",
                key: 1
              }, {
                value: false,
                name: "false",
                key: 2
              }]}
              handleChange={e => setShowProduct(e.target.value)}
            />
            <br/><br/><br/>

            <Button type="submit">
              Add product
            </Button>

          </form>
        </div>
      </Modal>

      {/* <div className="manageProducts">

        <table border="0" cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              <th>
                <h1>
                  Manage Products
                </h1>
              </th>
            </tr>
            <tr>
              <td>
                <table className="results" border="0" cellPadding="10" cellSpacing="0">
                  <tbody>
                    {(Array.isArray(data) && data.length > 0) && data.map((product, index) => {
                      const {
                        productName,
                        productThumbnail,
                        productPrice,
                        documentID,
                        // productAccessories
                      } = product;

                      return (
                        <tr key={index}>
                          <td>
                            <img className="thumb" src={productThumbnail} alt={productName} />
                          </td>
                          <td>
                            {productName}
                          </td>
                          <td>
                            £{productPrice}
                          </td>
                          <td>
                            <Button onClick={() => dispatch(deleteProductStart(documentID))}>
                              Delete
                            </Button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>

              </td>
            </tr>
          </tbody>
        </table>

      </div> */}

    </div>
  );
}

export default Admin;