import { createSelector } from 'reselect';
import { discountPerc, freeShipping } from '../../Utils';


const DiscPrice = ((100-discountPerc)/100).toFixed(2);


export const selectCartData = state => state.cartMetaData;

export const selectCartItems = createSelector(
  [selectCartData],
  cartMetaData => cartMetaData.cartItems
);

export const selectShipType = createSelector(
  [selectCartItems],
  cartItems => 
    cartItems.filter(arr => arr.shipType)[0].shipType
);


export const selectCartOnlyItems = createSelector(
  [selectCartItems],
  cartItems =>
    cartItems.filter(arr => typeof arr.quantity == "number")
);

export const selectCartItemsCount = createSelector(
  [selectCartOnlyItems],
  cartItems =>
    cartItems.reduce(
      (quantity, cartItem) =>
        quantity + cartItem.quantity
      , 0)
);

let shippingConvert = (type) => {
  switch (type) {
    case 'standard':
        return 0;
    case 'special':
        return 8;
    case 'specialSaturday':
        return 13;
    default:
        return 0;
  }
}

export const selectCartTotalforShippingDisc = createSelector(
  [selectCartOnlyItems],
  cartItems =>
    cartItems.reduce(
      (quantity, cartItem) =>
        quantity + cartItem.quantity * cartItem.productPrice * DiscPrice,
    0)
);

export const selectCartTotalforShipping = createSelector(
  [selectCartOnlyItems],
  cartItems =>
    cartItems.reduce(
      (quantity, cartItem) =>
        quantity + cartItem.quantity * cartItem.productPrice,
    0)
);



export const selectCartTotal = createSelector(
  [selectCartTotalforShipping, selectShipType],
  (cartTotal, shipType) =>
    (
      (shippingConvert(shipType) > 0) ?
      (cartTotal + shippingConvert(shipType)) :
      ((cartTotal + shippingConvert(shipType)) >= freeShipping ) ?
      (cartTotal + shippingConvert(shipType)) :
      (cartTotal + shippingConvert(shipType)) +3
    )
);

export const selectShippingCharges = createSelector(
  [selectCartTotalforShippingDisc, selectShipType],
  (cartTotal, shipType) =>
    (
      (shippingConvert(shipType) > 0) ?
      (shippingConvert(shipType)) :
      ((cartTotal + shippingConvert(shipType)) >= freeShipping ) ?
        0 :
        3
    )
);


