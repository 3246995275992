import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectCartOnlyItems, selectCartTotalforShipping,
  selectCartTotalforShippingDisc, selectShipType, selectShippingCharges } from './../../redux/Cart/cart.selectors';
import { shippingTypeChange } from './../../redux/Cart/cart.actions';
import { selectCurrUser } from '../../redux/User/user.selectors';
import { createStructuredSelector } from 'reselect';
import './styles.scss';
import Button from './../forms/Button';
import Item from './Item';
import Grid from '@material-ui/core/Grid';
import { discountPerc, freeShipping, holiday, holidayDate } from '../../Utils';
// import FormSelect from './../../components/forms/FormSelect';


import stripePaymentProtection from './../../assets/Checkout/stripePaymentProtection.png';


// Radio material ui
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';




const mapState = createStructuredSelector({
  cartItems: selectCartOnlyItems,
  total: selectCartTotalforShipping,
  shippingTotal: selectCartTotalforShippingDisc,
  pickShipType: selectShipType,
  currUser: selectCurrUser,
  shippingcharges: selectShippingCharges,
  // receipt_email: selectUserID,
});

const Checkout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { cartItems, total, shippingTotal, pickShipType, currUser, shippingcharges } = useSelector(mapState);
  const errMsg = 'You have no items in your cart.';
  const formattedTotal = parseFloat(total).toFixed(2);
  const DiscTotal = (shippingTotal + shippingcharges).toFixed(2);
  // const isAdmin = checkUserIsAdmin(currUser);
  // let cust_email = ""; 

  let itemsforStripe = "";

  let orderItemsforStripe = cartItems.map(item => {
    const { productName, size, quantity, productPrice } = item;
    itemsforStripe = `${productName}\n${size}\n${quantity}\n£${productPrice}\n\n`

    return {
      itemsforStripe
    };
  })

  let finalDataforStripe = [];

    for (let i = 0; i < 20; i++) {
      // text += cars[i] + "<br>";
      if(typeof orderItemsforStripe[i]  != 'undefined') {
        finalDataforStripe[i] =  Object.values(orderItemsforStripe[i])[0]
      } else {
        finalDataforStripe[i] = ""
      }
    }

    const metadata = finalDataforStripe;


// radio material
  // const [value, setValue] = React.useState('standard');

  const handleChange = (event) => {
    // setValue(event.target.value);
    if(event.target.value){
      dispatch(
        shippingTypeChange(event.target.value)
      );
    }
  };

  // useEffect(() => {
    
  //   if (currUser) {
  //     cust_email = currUser.email;
  //   }
    
  // }, [currUser, cust_email]);


  return (
    <div className="checkout">
      <h1>
        Checkout
      </h1>

      {cartItems.length > 0 ? (

        <>

        <div className="cartItems">
      
          {cartItems.map((item, pos) => {
            return (
                  <Item key={pos} {...item} />
            );
          })}
        </div>

        

        <div className="cartDesc">
        


                        
          <div className="radio">


            <FormControl component="fieldset">
              <FormLabel component="legend"></FormLabel>
              <RadioGroup aria-label="deliveryType" id="standard" name="deliveryType" 
                value={pickShipType} 
                onChange={handleChange}>
                
                
                <FormControlLabel value="standard" control={<Radio />} 
                  label={<div className="RadioLabelName">
                      {/* <text>STANDARD DELIVERY via Hermes (3-5 working days)</text> */}
                      STANDARD DELIVERY via Hermes (3-5 working days)
                      {/* STANDARD DELIVERY via Hermes (3-5 days from 17th April) */}
                    </div>}
                />
                <label htmlFor="standard" className={"radio-tile-label "}> 
                  <ul>
                    <li>
                      <b>FREE standard Home delivery over £{freeShipping}.00.</b>
                    </li>
                    <li>
                      £3.00 Flat fee for orders under £{freeShipping}.00.
                    </li>
                  </ul>
                </label>

                <FormControlLabel value="special" control={<Radio />} //disabled
                  label={<div className="RadioLabelName">
                      {/* <text>ROYAL MAIL SPECIAL DELIVERY GUARANTEED BY 1 PM</text> */}
                      ROYAL MAIL SPECIAL DELIVERY GUARANTEED BY 1 PM
                    </div>}
                />
                <label htmlFor="special" className={"radio-tile-label "}> 
                  <ul>
                    <li>
                      <b>£8.00 flat fee for delivery.</b>
                    </li>
                    <li>
                      Order by 12 noon, one day before. 
                    </li>
                    <li>
                      Guaranteed delivery by 1 pm next working day.
                    </li>
                  </ul>
                </label>



                <FormControlLabel value="specialSaturday" control={<Radio />} //disabled
                  label={<div className="RadioLabelName">
                      {/* <text>ROYAL MAIL SPECIAL DELIVERY GUARANTEED BY 1 PM WITH SATURDAY GUARANTEE</text> */}
                      ROYAL MAIL SPECIAL DELIVERY GUARANTEED BY 1 PM WITH SATURDAY GUARANTEE
                    </div>}
                />
                <label htmlFor="specialSaturday" className={"radio-tile-label "}> 
                    <ul>
                      <li>
                        <b>£13.00 flat fee for delivery.</b>
                      </li>
                      <li>
                        Order by 3 pm on Friday
                      </li>
                      <li>
                        Guaranteed delivery by 1 pm on Saturday
                      </li>
                    </ul>
                  </label>
                
              </RadioGroup>
            </FormControl>


          </div>



          <h3>
            
            SHIPPING CHARGES: {(shippingcharges === 0 ? 'Free' : '£'+shippingcharges)}

          </h3>

          <h3>
            {
            (discountPerc === 0)
              ?
                ("TOTAL: £" + total.toFixed(2))
              :
              <span className="price">
                TOTAL:&nbsp;  
                <span className="strike">
                  £{total.toFixed(2)}
                </span>
                &nbsp;£{DiscTotal}
              </span>
            }
          


            {/* { (discountPerc === 0) ? 
                  (productPrice)
                  : 
                  <>
                    <Typography display="inline" variant="h5" color="textSecondary" style={{ textDecoration: "line-through" }}>
                      £{productPrice}
                    </Typography>
                    <Typography display="inline" variant="h5" color="textSecondary" >
                      &nbsp; £{DiscPrice}
                    </Typography>
                  </>
                } */}
          </h3>

          {
            (holiday) &&
              <h4>We are away on holiday for a few days. You can place the order, but they will be processed from {holidayDate}.</h4>
          }
          {/* <h4>We are away on holiday for a few days. You can place the order, but they will be processed from 27th July. Happy Weekend!</h4> */}

          <Grid container spacing={3} className="cartBtns" > 
            <Grid item xs={12} sm={6} >
                <Button className="btn cartBtn" onClick={() => history.goBack()}>
                  Continue Shopping
                </Button>
              </Grid>
              
              <Grid item xs={12} sm={6} >


                {/* <Button className="btn cartBtn" onClick={() => history.push('/payment')}>
                {(currUser) ? 'Pay Now' : 'Login and Checkout'}
                </Button> */}

              {(currUser) ?

                <div className="paymentDetails">
               
    
                  <form action="https://europe-west2-madeforme-40d4c.cloudfunctions.net/api/create-checkout-session" method="POST" >
                    {/* <input type="hidden" name="priceId" value="50" /> */}
                    {/* <input type="hidden" name="recipientName" value={recipientName}/> */}
                    <input type="hidden" name="receipt_email" value={currUser.email}/>
                    <input type="hidden" name="amount" value={formattedTotal}/>
                    {/* <input type="hidden" name="admin" value={isAdmin}/> */}
                    <input type="hidden" name="productsMetadata" value={metadata}/>
                    {/* <input type="hidden" name="priceID" value={'price_1JlUjTLAN4m485Gq0ziaJLkj'}/> */}
                    <input type="hidden" name="shipping_charges" value={shippingcharges}/>



                    <Button
                      className="btn cartBtn"
                      type="submit"
                    >
                      Pay Now : £{parseFloat(DiscTotal).toFixed(2)}
                    </Button>

                  </form>

                </div>
                
                :

                <Button
                  className="btn cartBtn"
                  type="submit"
                  onClick={() => history.push('/login')}
                >
                    Login and Checkout
                </Button>

              }

              </Grid>

          </Grid>

         
          <div className="stripeProtectContainer">
            <img src={stripePaymentProtection} alt="Logo" />
          </div>

          <div className="stripeSecureNote">
            <p> <i>
              All payments are powered by Stripe.com. Your personal information will never be shared, and all transactions are protected by Stripe.com
            </i> </p>
          </div>


        </div>
      </>
      ) : (
            <p>
              {errMsg}
            </p>
          )}

    </div>
  );
};

export default Checkout;