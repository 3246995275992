
export const existingCartItem = ({
    prevCartItems,
    nextCartItem
  }) => {
    return prevCartItems.find(
      cartItem => cartItem.documentID === nextCartItem.documentID
    );
  };
  
  export const existingSize = ({
    prevCartItems
  }) => {
    return (prevCartItems.some(cartItem => cartItem.hasOwnProperty('sizenew')))
  };

  export const existingShip = ({
    prevCartItems
  }) => {
    return (prevCartItems.some(cartItem => cartItem.hasOwnProperty('shipType')))
  };

  export const handleAddToCart = ({
    prevCartItems,
    nextCartItem
  }) => {

    const sizemap = prevCartItems.map(cartItem => cartItem.sizenew);
    const newSize = sizemap[0];

    const quantityIncrement = 1;
    const cartItemExists = existingCartItem({ prevCartItems, nextCartItem });
  
    if (cartItemExists) {
      return prevCartItems.map(cartItem =>
        cartItem.documentID === nextCartItem.documentID
          ? {
            ...cartItem,
            quantity: cartItem.quantity + quantityIncrement,
          } : cartItem
      );
    }
  
    return [
      ...prevCartItems,
      {
        ...nextCartItem,
        quantity: quantityIncrement,
        size: newSize,
      }
    ];



  };
  
  export const handleRemoveCartItem = ({
    prevCartItems,
    cartItemToRemove
  }) => {
    return prevCartItems.filter(item => item.documentID !== cartItemToRemove.documentID);
  }
  
  export const handleReduceCartItem = ({
    prevCartItems,
    cartItemToReduce
  }) => {

    const existingCartItem = prevCartItems.find(cartItem =>
      cartItem.documentID === cartItemToReduce.documentID);
  
    if (existingCartItem.quantity === 1) {
      return prevCartItems.filter(
        cartItem => cartItem.documentID !== existingCartItem.documentID
      );
    }
  

    return prevCartItems.map(cartItem =>
      cartItem.documentID === existingCartItem.documentID ?
      {
        ...cartItem,
        quantity: cartItem.quantity - 1,
      } : cartItem)
  };

  export const handleSize = ({
    prevCartItems,
    size
  }) => {

    const cartSizeExists = existingSize({prevCartItems});

    if (cartSizeExists) {
      return prevCartItems.map(cartItem =>
        cartItem.sizenew
          ? {
            ...cartItem,
            sizenew: size,
          } : cartItem
      );
    }

    return [
      ...prevCartItems,
      {
        sizenew: size,
      }
    ];

  };


  export const handleShippingType = ({
    prevCartItems,
    shippingType
  }) => {

    const cartShipExists = !!1;
    if (cartShipExists) {
      const res = prevCartItems.map(cartItem =>
        cartItem.shipType
          ? {
            ...cartItem,
            shipType: shippingType,
          } : cartItem
      );

      return res

      
    }

    return [
      ...prevCartItems,
      {
        shipType: shippingType,
      }
    ];

  };
