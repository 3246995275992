import React from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import sizesImage from './../../assets/SizeGuide/sizesImage.jpg';

import './styles.scss';


function createDataDresses(size, chest, waist, length) {
    return { size, chest, waist, length };
}

function createDataShoes(uk, eu) {
    return { uk, eu};
}

const dresses = [
    createDataDresses('1-2 yr', 56, 54, 45),
    createDataDresses('2-3 yrs', 56, 52, 55),
    createDataDresses('3-4 yrs', 60, 56, 60),
    createDataDresses('5 yrs', 64, 60, 65),
    createDataDresses('6 yrs', 68, 64, 70),
    createDataDresses('7-8 yrs', 72, 68, 75),
    createDataDresses('9 yrs', 76, 72, 80)
];

const shoes = [
    createDataShoes(4,21),
    createDataShoes(5,22),
    createDataShoes(6,23),
    createDataShoes(7.5,24),
    createDataShoes(8,25),
    createDataShoes(8.5,26),
    createDataShoes(9,27),
	createDataShoes(10,28),
    createDataShoes(11,29),
    createDataShoes(11.5,30),
    createDataShoes(12,31),
    createDataShoes(13,32),
    createDataShoes(1,33)
];

const SizeChart = () => {
    window.scrollTo(0, 0);
    return (

        <div className="SizeChartContainer">

        <h2>
            Size chart
        </h2>

            <Grid container spacing={0} className="sizeGuideContainer" > 
                <Grid item xs={12} sm={7} >

                    <TableContainer component={Paper}>
                        <Table className="tableContainer" aria-label="simple table">
                            <TableHead>
                                <TableRow
                                    style ={{ background : "#cab7e1" }}
                                    >
                                    <TableCell className="SizeGuideTHead" >Size</TableCell>
                                    <TableCell className="SizeGuideTHead" >Chest</TableCell>
                                    <TableCell className="SizeGuideTHead" >Waist</TableCell>
                                    <TableCell className="SizeGuideTHead" >Length</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dresses.map((row, index) => (
                                    <TableRow
                                        key={row.size}
                                        style ={ index % 2? { background : "#ede6f4" }:{ background : "white" }}
                                        >
                                            <TableCell className="SizeGuideTCell" >{row.size}</TableCell>
                                            <TableCell className="SizeGuideTCell" >{row.chest}"</TableCell>
                                            <TableCell className="SizeGuideTCell" >{row.waist}"</TableCell>
                                            <TableCell className="SizeGuideTCell" >{row.length}"</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div className="Note">

                        <h4 >
                            Note:
                        </h4>
                        <h4 >
                            1. All sizes measurements in the table are in centimeters.
                        </h4>
                        <h4>
                            2. The size may have 1-3cm difference. (1cm = 0.39 inches)
                        </h4>
                        <h4>
                            3. Before buying if you have any questions, please contact us at <a href="mailto:info@madeformefashion.com">
                            info@madeformefashion.com
                          </a>.
                        </h4>
                    </div>



                </Grid>
                
                <Grid item xs={12} sm={5} >
                    <img src={sizesImage} alt="Sizes reference" />
                </Grid>


                <h2 className="shoestitle">
                    Shoes
                </h2>

                <Grid item xs={8} sm={3} >
                    <TableContainer component={Paper}>
                        <Table className="tableContainer" aria-label="simple table">
                            <TableHead>
                                <TableRow
                                    style ={{ background : "#cab7e1" }}
                                    >
                                    <TableCell className="SizeGuideTHead" >UK</TableCell>
                                    <TableCell className="SizeGuideTHead" >EU</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {shoes.map((row, index) => (
                                    <TableRow
                                        key={row.uk}
                                        style ={ index % 2? { background : "#ede6f4" }:{ background : "white" }}
                                        >
                                            <TableCell className="SizeGuideTCell" >{row.uk}</TableCell>
                                            <TableCell className="SizeGuideTCell" >{row.eu}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>
            </Grid>
        </div>
    )
}

export default SizeChart;