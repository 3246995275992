import React, { useState, useEffect } from 'react';
import { Link, useLocation  } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import { signOutUserStart } from './../../redux/User/user.actions';
// import { selectCartItemsCount } from './../../redux/Cart/cart.selectors';
// import Button from './../forms/Button';
// import downloadSVG from './../../assets/SVG/download.svg';
import './styles.scss';


// const Logo = "https://ik.imagekit.io/madeformefashion/tr:w-400/logo.svg";
// const Logo = "https://ik.imagekit.io/trophyhouseindore/tr:w-400/logo.svg";
// const Logo = "https://ik.imagekit.io/trophyhouseindore/TH_Logo.svg";

const Logo = "https://ik.imagekit.io/trophyhouseindore/THLogo.svg?updatedAt=1686294350744";



// const mapState = (state) => ({
//   currentUser: state.user.currentUser,
//   totalNumCartItems: selectCartItemsCount(state)
// });

const Header = props => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(false);
  // const dispatch = useDispatch();
  // const { currentUser, totalNumCartItems } = useSelector(mapState);

  // const signOut = () => {
  //   dispatch(signOutUserStart());
  // };

  useEffect(() => {
    setActiveMenu(false);
  }, [location]);

  return (
  <>

    <header className="header header1">
      <div className="wrap">

        <div className="logoBrand">

          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="Trophy House logo" />
            </Link>
            {/* <p className="logotag">A Mark of Quality</p> */}
            
          </div>

          <div className="brandName">
            <h1 >TROPHY HOUSE</h1>
          </div>

        </div>


        <nav className={`mainMenu ${activeMenu ? 'active' : ''} menuToHide`}>
          <ul>
            <li>
              <Link to="/">
                Home
              </Link>
            </li>
            <li>
              <Link to="/search">
                SHOP ALL
              </Link>
            </li>
            <li>
              <Link to="/search/dresses">
                APPAREL
              </Link>
            </li>
            <li>
              <Link to="/search/accessories">
                ACCESSORIES
              </Link>
            </li>
            <li>
              <Link to="/about-us">
                ABOUT US
              </Link>
            </li>

            {/* {currentUser && [
                <li key={1}>
                  <Link to="/dashboard">
                    <i className="fas fa-user-circle"></i>
                      My Account
                  </Link>
                </li>,
                <li key={2}>
                  <Link to="/#" onClick={() => signOut()}>
                    <i className="fas fa-sign-out-alt"></i>
                      LogOut
                  </Link>
                </li>
              ]}
              {!currentUser && [
                <li key={1} className="hideOnMobile">
                  <Link to="/registration">
                    Register
                  </Link>
                </li>,
                <li key={2}>
                  <Link to="/login">
                    <i className="fas fa-user-circle"></i>
                    Login
                  </Link>
                </li>
              ]} */}
          </ul>
        </nav>


        <div className="callToActions">


              {/* <div className="catalogue">
                <Button
                  className="btn ctaBtn"
                  type="submit"
                >
                  <Link to="/cart" >Download Catalogue</Link>
                  
                </Button>

              </div> */}


          {/* <ul>

            <li>
              <Link to="/cart" >
                <p>({totalNumCartItems})</p>
                <i className="fas fa-shopping-basket"></i>
              </Link>
            </li>

            {currentUser && [
              <li key={1} className="hideOnMobile">
                <Link to="/dashboard">
                  My Account
                  <i className="fas fa-user-circle"></i>
                </Link>
              </li>,
              <li key={2}  className="hideOnMobile">
                <span onClick={() => signOut()}>
                  LogOut
                  <i className="fas fa-sign-out-alt"></i>
                </span>
              </li>
            ]}

            {!currentUser && [
              <li key={1} className="hideOnMobile">
                <Link to="/registration">
                  Register
                </Link>
              </li>,
              <li key={2}  className="hideOnMobile">
                <Link to="/login">
                  Login
                  <i className="fas fa-user-circle"></i>
                </Link>
              </li>
            ]}

            <li className="mobileMenu">
              <span onClick={() => setActiveMenu(!activeMenu)}>
                <i className="fas fa-bars"></i>
              </span>
            </li>

          </ul> */}

        </div>
      </div>
    </header>



    {/* <header className="header header2">
    <div className="wrap">
      
      <nav className={`mainMenu ${activeMenu ? 'active' : ''}`}>
        <ul>
          <li>
            <Link to="/about-us">
              Trophies
            </Link>
          </li>
          <li>
            <Link to="/search">
              Acrylic
            </Link>
          </li>
          <li>
            <Link to="/search/dresses">
              Crystal
            </Link>
          </li>
          <li>
            <Link to="/search/accessories">
              Gifts Items
            </Link>
          </li>
          <li>
            <Link to="/about-us">
              Medals
            </Link>
          </li>
          <li>
            <Link to="/about-us">
              Badges
            </Link>
          </li>
          <li>
            <Link to="/about-us">
              Catalogue
            </Link>
          </li>
        </ul>
      </nav>

    </div>
  </header> */}


  </>
  );
};

Header.defaultProps = {
  currentUser: null
};

export default Header;
