import React from 'react';
import { Link } from 'react-router-dom';
import Button from './../../forms/Button';

import { discountPerc } from '../../../Utils';

const Product = (product) => {

  const {
    documentID,
    productThumbnail,
    productName,
    productPrice,
    soldOut
  } = product;

  if (!documentID || !productThumbnail || !productName ||
    typeof productPrice === 'undefined') return null;

  const configAddToCartBtn = {
    type: 'button'
  };

  const DiscPrice = (Number(productPrice) * (100-discountPerc)/100).toFixed(2);

  // const frontImage = productThumbnail.replace("https://ik.imagekit.io/madeformefashion/","https://ik.imagekit.io/madeformefashion/tr:w-300/");

  let prodThumbFormat = productThumbnail;

  if(!productThumbnail.startsWith("https://ik.imagekit.io/")){

    const docIDformat = product.documentID.replace(/-/g,'').replace(/\s/g, "");
    const nameedit = "https://ik.imagekit.io/madeformefashion/tr:w-300/Products/"+docIDformat+"/"
    prodThumbFormat = productThumbnail.replace(/.\w+.jpg/g,".jpg").replace("/static/media/",nameedit);

  }

  return (
    <div className="product">

      
      {soldOut ? 
        <div className='thumb imageOverlay'>
          <Link to={`/product/${documentID}`}>
            <img src={prodThumbFormat} alt={productName} />
            <div className="soldOutOverlay">SOLD OUT</div>
          </Link>
        </div>
        :
        <div className='thumb' >
          <Link to={`/product/${documentID}`}>
            <img src={prodThumbFormat} alt={productName} />
          </Link>
        </div>
      } 


      {(discountPerc !== 0) && 
         <div className="discDesc details">
          <ul>
            <li>
              <span>
                {discountPerc}% off
              </span>
            </li>
          </ul>
        </div>
      }

      <div className="details">
        <ul>
          <li>
            <span className="name">
              <Link to={`/product/${documentID}`}>
                {productName}
              </Link>
            </span>
          </li>

         { (discountPerc === 0) ? 
            <li>
              <span className="price noOffer">
                £{productPrice}
              </span>
            </li>
            :
            <li>
              <span className="price">
                <span className="orgPrice">
                  £{productPrice}
                </span>
                £{DiscPrice}
              </span>
            </li>
          }

          <li>
            <div className="addToCart">
            <Link to={`/product/${documentID}`}>
              <Button {...configAddToCartBtn} >
                {soldOut ? 'Sold Out' : 'Shop Now'} 
              </Button>
              </Link>
            </div>
          </li>
        </ul>
      </div>

    </div>
  );
};

export default Product;