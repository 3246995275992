import React from 'react';
// import { Link } from 'react-router-dom';
import './styles.scss';
import './../../components/Information/styles.scss';

// const Unicorn_Front = 'https://ik.imagekit.io/madeformefashion/tr:w-200/Products/Unicorn/Unicorn_Front.jpg';
// const RuffleSleeves_Front = 'https://ik.imagekit.io/madeformefashion/tr:w-200/Products/RuffleSleeves/RuffleSleeves_Front.jpg';
// const PinkSequinSandal_1 = 'https://ik.imagekit.io/madeformefashion/tr:w-200/Products/Accessories/PinkSequinSandal/PinkSequinSandal_1.jpg';
// const ElsaSequin_Front = 'https://ik.imagekit.io/madeformefashion/tr:w-200/Products/ElsaSequin/ElsaSequin_Front.jpg';

// const cust1 = 'https://lh3.googleusercontent.com/a-/ALV-UjWTcPisAMni4cZ1abULuS6M74BQnBl9IB6L0NoJc1nfM_8=w60-h60-p-rp-mo-ba2-br100';


const Reviews = () => {

    return (
        <div className="infoContainer">
            

            <h2 className="customers">
                What our customers say
            </h2>

            <div className='reviewContainer'>
                
                <div className='reviewContent'>
                    {/* <Link to="https://lh3.googleusercontent.com/a-/ALV-UjWTcPisAMni4cZ1abULuS6M74BQnBl9IB6L0NoJc1nfM_8=w60-h60-p-rp-mo-ba2-br100">
                        <img src={Unicorn_Front} alt={"UnicornFront"}/>
                    </Link> */}
                    {/* <img src={Unicorn_Front} alt={"Customer1"}/> */}
                    <h4>Very nice place very big amount of trophy each and every type of trophy so many design great customer satisfaction nice quality products.</h4>
                    <h5>- Dharmesh</h5>
                </div>

                <div className='reviewContent'>
                    {/* <Link to="/product/PinkSequinShoes">
                        <img src={PinkSequinSandal_1} alt={"PinkSequinSandal_1"} />
                    </Link> */}
                    <h4>Perfect place for the selection of trophies due to their collection. Rates are appropriate and the quality of the trophy is very good.</h4>
                    <h5>- Vaibhav</h5>
                </div>

                <div className='reviewContent'>
                    {/* <Link to="/product/RuffleSleevesDress">
                        <img src={RuffleSleeves_Front} alt={"RuffleSleeves_Front"} />
                    </Link> */}
                    <h4>Grt trophy 🏆 are mk all events point of view.</h4>
                    <h5>- Rishabh</h5>
                </div>

                <div className='reviewContent'>
                    {/* <Link to="/product/ElsaSequinDress">
                        <img src={ElsaSequin_Front} alt={"ElsaSequinFront"} />
                    </Link> */}
                    <h4>Great service. Variety of products are available. Satisfied.</h4>
                    <h5>- Vikas</h5>
                </div>

            </div>

            {/* <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14721.2789102132!2d75.8570334!3d22.7163546!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd6d5c629411%3A0xcc6d231724b10699!2sTrophy%20House!5e0!3m2!1sen!2sin!4v1694876569290!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div> */}

        <div className="Maps">

            <div className="MapsContainer">

                <h4>Neighbourhood Showroom</h4>
                <p>166 Usha Nagar Extn., Indore (MP)</p>
                <div className="google-maps">
                    {/* <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14721.2789102132!2d75.8570334!3d22.7163546!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd6d5c629411%3A0xcc6d231724b10699!2sTrophy%20House!5e0!3m2!1sen!2sin!4v1694876569290!5m2!1sen!2sin" 
                        width="350" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Embedded Maps"
                        ></iframe> */}

                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.582854203793!2d75.8379246!3d22.706567699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd49b10a93f3%3A0xc7bc3d926032109c!2sTROPHY%20HOUSE!5e0!3m2!1sen!2sin!4v1713436832763!5m2!1sen!2sin"
                        width="350" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Embedded Maps"
                        ></iframe>
                </div>
            </div>

            <div className="MapsContainer">

                <h4>Central Showroom</h4>
                <p>74 Jawahar Marg, Nandlalpura Square, Indore (MP)</p>
                <div className="google-maps">
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14721.2789102132!2d75.8570334!3d22.7163546!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd6d5c629411%3A0xcc6d231724b10699!2sTrophy%20House!5e0!3m2!1sen!2sin!4v1694876569290!5m2!1sen!2sin" 
                        width="350" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Embedded Maps"
                        ></iframe>
                </div>
            </div>


        </div>
            {/* <div className={classes.root}>
                <Paper className={classes.paper} >
                    <Grid container spacing={0}>
                        <Grid item sm={3} className="customerGrid">
                            <ButtonBase className="customerImg">
                                <Link to="/product/DeluxeUnicornCostumewithAccessories">
                                    <img src={Unicorn_Front} alt={"UnicornFront"} verticalalign={"center"}/>
                                </Link>
                            </ButtonBase>
                        </Grid>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2} >
                                <Grid item xs className="content">
                                    <Typography gutterBottom variant="h5" fontWeight="fontWeightBold"  >
                                    {"“A beautiful dress that also fits well to little girls of all sizes. Kashish, I couldn’t thank you enough for your expert help in selecting the right dress and size for my LO. Highly recommend the dress and personalised service”."} 
                                    </Typography>
                                    <Rating name="read-only" value={5} readOnly />
                                    <Typography gutterBottom variant="h5" fontWeight="fontWeightBold" >
                                    {"- Mira"} 
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper className={classes.paper}>
                    <Grid container spacing={0} direction="row-reverse" >
                        <Grid item className="customerGrid">
                            <ButtonBase className="customerImg">
                                <Link to="/product/RuffleSleevesDress">
                                    <img src={RuffleSleeves_Front} alt={"RuffleSleeves_Front"} />
                                </Link>
                            </ButtonBase>
                        </Grid>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs className="content">
                                    <Typography gutterBottom variant="h5" fontWeight="fontWeightBold" >
                                    {"“Dress arrived in time. It looks even better than the pictures. My daughter is very happy. Thank you!”"} 
                                    </Typography>
                                    <Rating name="read-only" value={5} readOnly />
                                    <Typography gutterBottom variant="h5" fontWeight="fontWeightBold" >
                                    {"- Catherine"} 
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper className={classes.paper}>
                    <Grid container spacing={0} >
                        <Grid item sm={3} className="customerGrid">
                            <ButtonBase className="customerImg">
                                <Link to="/product/PinkSequinShoes">
                                    <img className={classes.img} src={PinkSequinSandal_1} alt={"PinkSequinSandal_1"} />
                                </Link>
                            </ButtonBase>
                        </Grid>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs className="content">
                                    <Typography gutterBottom variant="h5" fontWeight="fontWeightBold" >
                                    {"“Shoes are gorgeous! my daughter wears them everywhere, she loves them. Thank you”"} 
                                    </Typography>
                                    <Rating name="read-only" value={5} readOnly />
                                    <Typography gutterBottom variant="h5" fontWeight="fontWeightBold" >
                                    {"- Sarah"} 
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper className={classes.paper}>
                    <Grid container spacing={0} direction="row-reverse" >
                        <Grid item className="customerGrid">
                            <ButtonBase className="customerImg">
                                <Link to="/product/ElsaSequinDress">
                                    <img src={ElsaSequin_Front} alt={"ElsaSequinFront"} />
                                </Link>
                            </ButtonBase>
                        </Grid>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs className="content">
                                    <Typography gutterBottom variant="h5" fontWeight="fontWeightBold" >
                                    {"“Saw a similar dress at high-street store and it was pricier hence ordered from made for me fashion and I am satisfied with my purchase.”"} 
                                    </Typography>
                                    <Rating name="read-only" value={5} readOnly />
                                    <Typography gutterBottom variant="h5" fontWeight="fontWeightBold" >
                                    {"- Amy"} 
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>


            </div> */}

        </div>
    )
}

export default Reviews;
