const cartTypes = {
    ADD_TO_CART: 'ADD_TO_CART',
    SIZE_PRODUCT: 'SIZE_PRODUCT',
    REMOVE_CART_ITEM: 'REMOVE_CART_ITEM',
    REDUCE_CART_ITEM: 'REDUCE_CART_ITEM',
    CLEAR_CART: 'CLEAR_CART',
    SIZE_CHANGE: 'SIZE_CHANGE',
    SHIPPING_TYPE: 'SHIPPING_TYPE'
  };
  
  export default cartTypes;