import React from 'react';
import './../../components/Information/styles.scss';
import { freeShipping } from './../../Utils';


const ShippingPolicy = () => {
    window.scrollTo(0, 0);
    return(
        <div className="infoContainer">
            <div className="Title">
                <h2>
                    Shipping POLICY
                </h2>
            </div>

            <div>
                <h5>
                    DELIVERY INFORMATION
                </h5>
                <p>
                    We use Hermes and Royal Mail to deliver our beautiful creations to your doorstep, within the United Kingdom. We can deliver internationally, the customer pays the cost of shipping and the cost depends on weight and your location. To enquire, please email us at info@madeformefashion.com.
                </p>
                <p>
                    All orders will be processed within 24 hours of receiving your order.
                </p>
                <p>
                    Please note:  During exceptional circumstances such as the Covid-19 pandemic, delivery time guarantees may be subject to change. To learn more about special delivery, please visit royalmail.com/specialdelivery.
                </p>
                



                <h5>
                    STANDARD DELIVERY
                </h5>
                <p>
                    Standard Home Delivery over £{freeShipping}.00 is free.
                </p>
                <p>
                    £3.00 flat fee for orders under £{freeShipping}.00.
                </p>
                <p>
                    Standard Home Delivery orders take 3-5 working days to arrive.
                </p>
                <p>
                    The customer receives a tracking number to track on Hermes website.
                </p>
                <p>
                    Customers will receive an email once the item has been dispatched. 
                </p>





                <h5>
                    ROYAL MAIL SPECIAL DELIVERY GUARANTEED BY 1 PM
                </h5>
                <p>
                    Order by 12 noon, one day before.
                </p>
                <p>
                    Guaranteed delivery by 1 pm next working day.
                </p>
                <p>
                    Customer pays for special delivery order.
                </p>
                <p>
                    £8.00 flat fee for delivery.
                </p>
                <p>
                    The customer receives a tracking number to track on the royal mail website.
                </p>






                <h5>
                    ROYAL MAIL SPECIAL DELIVERY GUARANTEED BY 1 PM WITH SATURDAY GUARANTEE
                </h5>
                <p>
                    Next day special Saturday delivery service.
                </p>
                <p>
                    Guaranteed delivery by 1 pm on Saturday.
                </p>
                <p>
                    Order by 3 pm on Friday.
                </p>
                <p>
                    £13.00 flat fee for delivery.
                </p>
                <p>
                    The customer receives a tracking number to track on the royal mail website.
                </p>




                <p>
                    <b>For any further queries, please contact info@madeformefashion.com</b>
                </p>




            </div>

        </div>
    )
}

export default ShippingPolicy;