import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import CTL from './../../../assets/Products/CTL.jpg';


const CompleteLook = () => {

    return(
      <div>
        <div className="access">
          <Link to="/search/accessories">
            <img src={CTL} alt={CTL}/>
          </Link>
        </div>
      </div>
    )
}


export default CompleteLook;