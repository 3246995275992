import React from 'react';
import ProductResults from './../../components/ProductResults';

const Search = () => {
  window.scrollTo(0, 0);
  return (
    <div className="searchPage">
      <ProductResults />
    </div>
  );
};

export default Search;
