import { auth } from './../../firebase/utils';
import { firestore } from './../../firebase/utils';

export const handleResetPasswordAPI = (email) => {
  const config = {
    url: 'https://www.madeformefashion.com/login'
  };

  return new Promise((resolve, reject) => {
    auth.sendPasswordResetEmail(email, config)
      .then(() => {
        resolve();
      })
      .catch(() => {
        const err = ['Email not found. Please try again.'];
        reject(err);
      });
  });
};

export async function getemailID() {
    firestore
        .collection('users')
        .where('newsletter', '==', true)
        .get()
        .then(snap => {
          const emailid = []
          snap.forEach(doc => {
            const data = doc.data()
            emailid.push(data.email)
          })
          prompt("Copy below email addresses", emailid);
        })
        .catch(err => {
          console.log(err)
      })

  }
