import { createSelector } from 'reselect';

export const selectUserData = state => state.user;

export const selectUserID = createSelector(
    [selectUserData],
    user => user.currentUser.email
  );

export const selectCurrUser = createSelector(
  [selectUserData],
  user => user.currentUser
  );
