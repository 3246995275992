import React from 'react';
import { useDispatch } from 'react-redux';
import { removeCartItem } from './../../../redux/Cart/cart.actions';
import './styles.scss';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { discountPerc } from '../../../Utils';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '4rem',
    marginBottom: '0',
    marginTop: '2rem',
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 900,
  },
  image: {
    maxWidth: 125,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));




const Item = (product) => {
  const dispatch = useDispatch();
  const {
    productName,
    productThumbnail,
    productPrice,
    quantity,
    documentID,
    size,
  } = product;

  const DiscPrice = (Number(productPrice) * (100-discountPerc)/100).toFixed(2);

  let prodThumbFormat = productThumbnail;

  if(!productThumbnail.startsWith("https://ik.imagekit.io/")){

    const docIDformat = product.documentID.replace(/-/g,'').replace(/\s/g, "");
    const nameedit = "https://ik.imagekit.io/madeformefashion/tr:w-300/Products/"+docIDformat+"/"
    prodThumbFormat = productThumbnail.replace(/.\w+.jpg/g,".jpg").replace("/static/media/",nameedit);

  }

  const handleRemoveCartItem = (documentID) => {
    dispatch(
      removeCartItem({
        documentID
      })
    );
  }

  const classes = useStyles();

  return (



<div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item>
            <ButtonBase className={classes.image}>
              {/* <img className={classes.img} alt="complex" src="/static/images/grid/complex.jpg" /> */}
              <img className={classes.img} src={prodThumbFormat} alt={productName} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="h4" fontWeight="fontWeightBold" >
                  {productName}
                </Typography>
                <Typography variant="h5" gutterBottom>
                  Size: {size}
                </Typography>
                <Typography variant="h5" color="textSecondary">
                  Quantity: {quantity}
                </Typography>
                <Typography variant="h5" color="textSecondary" display="inline" >
                Price:&nbsp;
                </Typography >
                { (discountPerc === 0) ? 
                  (productPrice)
                  : 
                  <>
                    <Typography display="inline" variant="h5" color="textSecondary" style={{ textDecoration: "line-through" }}>
                      £{productPrice}
                    </Typography>
                    <Typography display="inline" variant="h5" color="textSecondary" >
                      &nbsp; £{DiscPrice}
                    </Typography>
                  </>
                }
              </Grid>
              <Grid item>
                <Typography variant="h5" style={{ cursor: 'pointer' }}>
                  <div >
                    <span className="cartBtn remove" onClick={() => handleRemoveCartItem(documentID)}>
                    Remove
                    </span>
                  </div>
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              
              { (discountPerc === 0) ? 
                  <Typography variant="h5">{"£" + productPrice * quantity}</Typography>
                  : 
                  <>
                    <Typography variant="h5" style={{ textDecoration: "line-through", textAlign: "right" }}>
                      £{(Number(productPrice * quantity)).toFixed(2)}

                    </Typography>
                    <Typography variant="h5" >
                      £{(Number(DiscPrice * quantity)).toFixed(2)}
                    </Typography>
                  </>
                }
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>

  );
}

export default Item;
