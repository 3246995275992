import React, { useState, useEffect } from 'react';
import './styles.scss';
import YoutubeEmbed from "./YoutubeEmbed";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Images = (product) => {
    const {
        productName,
        productThumbnail,
        productThumbnail2,
        productThumbnail3,
        productThumbnail4,
        productThumbnail5,
        productThumbnail6,
        youtubeEmbedID
      } = product;
    
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);
    
    useEffect(() => {

      window.scrollTo(0, 0)
        setNav1(slider1);
        setNav2(slider2);
    
      }, [slider1, slider2] );



    const changeFormat = (a) => {
      
      let temp = "";
      if(typeof a != 'undefined') {
        temp  = a;
        if(!a.startsWith("https://ik.imagekit.io/")){
          const docIDformat = product.documentID.replace(/-/g,'').replace(/\s/g, "");
          // const nameedit = "https://ik.imagekit.io/madeformefashion/tr:w-300/Products/"+docIDformat+"/";
          const nameedit = "https://ik.imagekit.io/madeformefashion/Products/"+docIDformat+"/";
          temp = a.replace(/.\w+.jpg/g,".jpg").replace("/static/media/",nameedit);
        }
      }

      return temp
    }


      

    const NextArrow = ({currentSlide, slideCount, ...props}) => (
        <div {...props}>
          <i className="fas fa-chevron-right next-slick-arrow"></i>
        </div>
      );
     
      const PrevArrow = ({currentSlide, slideCount, ...props}) => (
        <div {...props}>
          <i className="fas fa-chevron-left prev-slick-arrow"></i>
        </div>
      );
     

    const settingsMain = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.slider-nav',
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,

      };

    const settingsThumbs = {
        slidesToShow: 3,    //slides to show on thumbnail
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: true,
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,
        centerPadding: '10px',
        vertical: true,
        verticalSwiping: true,
        // adaptiveHeight: true,
        prevArrow: false,
        nextArrow: false,
        // dots: false,

      };

      const slidesData = [
        {
            id: 1,
            title: productName,
            label: productName,
            image: changeFormat(productThumbnail),
            key: 1
        }, {
            id: 2,
            title: productName,
            label: productName,
            image: changeFormat(productThumbnail2),
            key: 2
        }, {
            id: 3,
            title: productName,
            label: productName,
            image: changeFormat(productThumbnail3),
            key: 3
        }, {
            id: 4,
            title: productName,
            label: productName,
            image: changeFormat(productThumbnail4),
            key: 4
        }, {
            id: 5,
            title: productName,
            label: productName,
            image: changeFormat(productThumbnail5),
            key: 5
        }, {
          id: 6,
          title: productName,
          label: productName,
          image: changeFormat(productThumbnail6),
          key: 6
      },
      ];

      const updatedSlidesData = slidesData.filter(
          image => image.image !== ''
      );

      return (

        
        <div className="image-container">

          <div className="thumbnail-slider-wrap">
            <Slider
                {...settingsThumbs}
                asNavFor={nav1}
                ref={slider => (setSlider2(slider))}>
    
                {updatedSlidesData.map((slide) =>
                  <div className="slick-slide" key={slide.id}>
                      <img className="slick-slide-image" src={slide.image} alt={slide.title} />
                  </div>
                )}

                {youtubeEmbedID &&
                  <i className="fas fa-play fa-3x"></i>
                }
            </Slider>
          </div> 

                
          <div className="slider-wrapper">
            <Slider
              {...settingsMain}
              asNavFor={nav2}
              ref={slider => (setSlider1(slider))}>

              {updatedSlidesData.map((slide) =>
                  <div className="slick-slide" key={slide.id}>
                      <img className="slick-slide-image" src={slide.image} alt={slide.title} />
                  </div>
              )}

            {youtubeEmbedID &&
              <YoutubeEmbed embedId={youtubeEmbedID }/>
            }
               
            </Slider>
          </div>
        </div>
      );
}
export default Images;