import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './styles.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontFamily: ['DM Sans', 'Open Sans', 'sans-serif'],
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    color: "#141823"
  },
  content: {
    fontSize: theme.typography.pxToRem(20),
    fontFamily: ['DM Sans', 'Open Sans', 'sans-serif'],
    fontWeight: theme.typography.fontWeightRegular,
    color: "#333",
    textAlign: 'justify',
    marginBlockStart: '0',  //not higher level
    marginBlockEnd: '0',
  }
}));

const DescriptionBox = () => {
  const classes = useStyles();

  const mapState = state => ({
    product: state.productsData.product,
  });

const { product } = useSelector(mapState);

const {
    productDesc,
    productComp,
    productCare,
  } = product;

  

  return (
    <div className={classes.root}>
      <Accordion expanded>
        <AccordionSummary
          aria-controls="description"
          id="description"
        >
          <Typography className={classes.heading}>DESCRIPTION</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content} component={'span'} >
              <div className={classes.content} dangerouslySetInnerHTML={{ __html: productDesc}}></div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="composition"
          id="composition"
        >
          <Typography className={classes.heading}>DETAILS</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className="detailsContainer" component={'span'} >
            <div className="detailsDiv" dangerouslySetInnerHTML={{ __html: productComp}}></div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="wash-care"
          id="wash-care"
        >
          <Typography className={classes.heading}>WASH CARE</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography className={classes.content} component={'span'} >
            <div className={classes.content} dangerouslySetInnerHTML={{ __html: productCare}}/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded>
        <AccordionSummary
          aria-controls="100% Secured Payment"
          id="securePayment"
        >
          <i className="fas fa-lock" style={{ paddingRight: "1rem" }} ></i>
          <Typography className={classes.heading}>100% SECURED PAYMENT</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content} component={'span'} >
              <Typography className={classes.content}>Safeguarding your personal information is our top priority. Made for me fashion uses SSL technology to guarantee 100% secure ordering for all customers.</Typography>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default DescriptionBox;