import React from 'react';
import './../../components/Information/styles.scss'


const ReturnPolicy = () => {
    window.scrollTo(0, 0);
    return(
        <div className="infoContainer">
            <div className="Title">
                <h2>
                    RETURN POLICY
                </h2>
            </div>

            <div className="returns">
                <h5>
                    RETURNS
                </h5>
                <p>
                    Made for me fashion is all about you falling in love with our collection.
                        Each creation is high in quality, craftsmanship, and is a designer piece in itself.
                        However, if you change your mind for any reason, we will be pleased to offer you an exchange or refund within 14 days of receiving your creation,
                        subject to following conditions:
                </p>
                    <ul>
                        <li>
                            Item(s) must be returned in their original condition and packaging: unworn, unwashed, unaltered and with all tags attached.
                        </li>
                        <li>
                            Any accessories including earrings, magic wand, hair accessories etc, cannot be returned due to health and safety reasons.
                        </li>
                        <li>
                            We are unable to refund the original postage cost. So, after deducting the postage cost the remaining balance will be refunded.
                        </li>
                        <li>
                            Return shipping method and associated costs are the responsibility of the customer.
                        </li>
                        <li>
                            Return delivery costs for faulty supplied items will be the responsibility of Made for me Fashion.
                        </li>
                        <li>
                            We recommend sending your return via a traceable method and retaining proof of postage until receiving confirmation that your return has been processed. Made for me Fashion is not responsible for returns that are not received.
                        </li>
                        <li>
                            Final sale items cannot be returned unless they are faulty.
                        </li>
                    </ul>
            </div>

            <div className="returnProcess">
                <h5>
                    RETURN PROCESS
                </h5>
                <p>
                    To return an item, simply email us on info@madeformefashion.com and provide us with the following information:
                </p>
                    <ul>
                        <li>
                            Order ID
                        </li>
                        <li>
                            Date of Purchase
                        </li>
                        <li>
                            Reason for return
                        </li>
                        <li>
                            In the event of a faulty item, we may request a picture of the fault
                        </li>
                        <li>
                            Once your return has been approved, you will receive an email from our customer support team with the instructions on how to send your item back to Made for me Fashion. Please note, the return shipping method and associated costs are the responsibility of the customer.
                        </li>
                    </ul>
                        
                        
                    <p>
                        We recommend sending your return via a traceable method and retaining proof of postage for peace of mind until receiving confirmation that your return has been processed.
                    </p>
                    <p><b>Made for me fashion is not responsible for returns that are lost in the transit or not received.</b></p>
                    <p>Upon receipt of the items, they will be checked to make sure they meet the conditions above and once cleared, you will receive your refund (excluding original shipping costs paid) within 5 business days of the receipt of return.</p>
            </div>

            <div>
                <h5>
                    Final Sale
                </h5>
                <p>
                    Please note that items listed as Final/Sale/Clearance are clearance stock and are unable to be refunded, unless the item has been received faulty.
                </p>

                <h5>
                    Faulty Goods
                </h5>
                <p>
                    Goods are classified as faulty if they are not of satisfactory quality, fit for purpose or as discribed. Please note that items which are damaged as a result of normal wear and tear; by accident; or through misuse will not be considered faulty.
                </p>

                <h5>
                    Repeated Returns
                </h5>
                <p>
                    Please note, should we identify unreasonable patterns of returns we may refuse future orders.
                </p>

                <h5>
                    Exchanges
                </h5>
                <p>
                    Should you require an alternative item please return the original item for a refund (following the instructions above) and place a new order for the alternative.
                </p>
            </div>

        </div>
    )
}

export default ReturnPolicy;