import React from 'react';
import ProductCard from './../../components/ProductCard';

const ProductDetails = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <ProductCard />
    </div>
  );
}

export default ProductDetails;
