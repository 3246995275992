import { firestore } from './../../firebase/utils';

export const handleAddProduct = product => {
  return new Promise((resolve, reject) => {

    firestore
      .collection('products')
      .doc(product.productName.replace(/ /g,''))
      .set(product)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  });
}

export const handleFetchProducts = ({ filterType }) => {
  return new Promise((resolve, reject) => {

    let ref = firestore.collection('products')
                .where('showProduct', '==', true)      // For Testing only
                .orderBy('rankforsorting', 'desc');

    if (filterType){
      const typeArr = filterType.split(' ');
      (typeArr.length > 1)  
        ?
          ref = ref.where('subProductCategory', '==', typeArr[1])
        :
          ref = ref.where('productCategory', '==', typeArr[0])
      ;
    }
    
    ref
      .get()
      .then(snapshot => {
        
        const data = [
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({
          data
        });
      })
      .catch(err => {
        reject(err);
      })
  })
}

export const handleDeleteProduct = documentID => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('products')
      .doc(documentID)
      .delete()
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  });
}

export const handleFetchProduct = (productID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('products')
      .doc(productID)
      .get()
      .then(snapshot => {

        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: productID
          });
        }
      })
      .catch(err => {
        reject(err);
      })
  })
}