import React from 'react';
// import { Link } from 'react-router-dom';

import './styles.scss';


const banner = 'https://ik.imagekit.io/trophyhouseindore/Banner.png';

const Carousel = props => {
  return (
    <div className="heroWrapper">
          {/* <div className="area">
            <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
          </div > */}

        <div className="heroContent">
        
          <div className="heroCTA">
            {/* <h1>CHIC ONLINE BOUTIQUE</h1> */}
            {/* <h2>for toddlers and girls</h2> */}
            {/* <h1>CHIC ONLINE BOUTIQUE for toddlers and girls</h1> */}
            {/* <h1>Your Destination for Quality Trophies, Awards and Corporate Gifts</h1> */}
            <h1>For Quality Trophies Awards and Corporate Gifts</h1>
            {/* <h4></h4> */}
            {/* <Link to="/search">
              <div className="LRLbutton" >
              Catalogue
              </div>
            </Link> */}
            <div className="heroImage">
              <img src={banner} srcSet={`${banner} 680w, ${banner} 980w`}  alt="Logo" />

            </div>
          </div>
        
        </div>

            <h4>
              We are the leading supplier and manufacturer of Trophies, Awards, Badges, Cups, Shields, Medals
               and Corporate Gifts.
            </h4>
      </div>
  );
};

export default Carousel;
