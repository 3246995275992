import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCartTotal, selectCartOnlyItems, selectCartItemsCount } from '../../redux/Cart/cart.selectors';
import { selectCurrUser } from './../../redux/User/user.selectors';
import { saveOrderHistory } from './../../redux/Orders/orders.actions';


import { createStructuredSelector } from 'reselect';
import './styles.scss';

const mapState = createStructuredSelector({
    total: selectCartTotal,
    cartItems: selectCartOnlyItems,
    itemCount: selectCartItemsCount,
    currUser: selectCurrUser,
  });

  
const PaymentSuccess = () => {
  
  window.scrollTo(0, 0);

  const { total, cartItems, itemCount, currUser } = useSelector(mapState);
  const dispatch = useDispatch();


  const formattedTotal = parseFloat(total).toFixed(2);
  
  
  
  useEffect(() => {
    
    const configOrder = {
        orderTotal: formattedTotal,
        orderItems: cartItems.map(item => {
        const { documentID, productThumbnail, productName,
            productPrice, quantity, size } = item;
  
        return {
            documentID,
            productThumbnail,
            productName,
            productPrice,
            quantity,
            size
        };
        })
    }
   
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
     
      if (currUser) {
        if (itemCount > 0) {
          dispatch(
            saveOrderHistory(configOrder)
          )
        }
      }

    }
    
  }, [itemCount, currUser, dispatch, formattedTotal, cartItems ]);
  
  return (
      <div className="SuccessContainer">

            {!currUser && 
            
              <>
                <div className="blank">
                </div>

                <div className="paying">

                  <div className="spinner">
                    <h2>wait</h2>
                  </div>
                </div>
              </>
            }

            {currUser && 
            
              <div className="OrderConfirmed">

                <div className="confirmedAnimation">
                  <div className="confetti">
                    <div className="confetti-piece"></div>
                    <div className="confetti-piece"></div>
                    <div className="confetti-piece"></div>
                    <div className="confetti-piece"></div>
                    <div className="confetti-piece"></div>
                    <div className="confetti-piece"></div>
                    <div className="confetti-piece"></div>
                    <div className="confetti-piece"></div>
                    <div className="confetti-piece"></div>
                    <div className="confetti-piece"></div>
                    <div className="confetti-piece"></div>
                    <div className="confetti-piece"></div>
                    <div className="confetti-piece"></div>
                      
                    <div className="successContent">
                      <h1>Thank you! </h1>
                      <h3>Hi { currUser.displayName }, your order is confirmed.</h3>
                      <h4>You will receive an email confirmation soon.</h4>
                    </div>


                    
                  </div>
                </div>

              </div>
            }
            
      </div>
    
  ) 

}

export default PaymentSuccess;