import React, { useEffect } from 'react';
import {
  TableContainer, Table, TableHead,
  TableBody, TableRow, TableCell
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setOrderDetails } from './../../redux/Orders/orders.actions';
import './styles.scss';

const columns = [
  {
    id: 'productThumbnail',
    label: ''
  },
  {
    id: 'productName',
    label: 'Name'
  },
  {
    id: 'productPrice',
    label: 'Price'
  },
  {
    id: 'quantity',
    label: 'Quantity'
  },
  {
    id: 'size',
    label: 'Size'
  }
]


const formatText = (columnName, columnValue) => {
  switch(columnName) {
    case 'productPrice':
      return `£${columnValue}`;
    case 'productThumbnail':
      return <img src={columnValue} max-width={250} alt={columnValue} />;
    default:
      return columnValue;
  }
}

const OrderDetails = ({ order }) => {
  const dispatch = useDispatch();
  const orderItems = order && order.orderItems;

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
      dispatch(
        setOrderDetails({})
      );
    }
  }, [dispatch]);

  return (
    <div className="OrderDetailsTC">
      <TableContainer>
        <Table>

          <TableHead>
            <TableRow>

              {columns.map((col, pos) => {
                return (
                  <TableCell
                    className="OrderDetailsTCell"
                    key={pos}
                    // style={styles}
                    align='center'
                  >
                    {col.label}
                  </TableCell>
                )
              })}

            </TableRow>
          </TableHead>

          <TableBody>

            {(Array.isArray(orderItems) && orderItems.length > 0) && orderItems.map((row, pos) => {
              return (
                <TableRow key={pos}>

                  {columns.map((col, pos) => {
                    const columnName = col.id;
                    const columnValue = row[columnName];

                    return (
                      <TableCell
                        className="OrderDetailsTCell OrderDetailsTCellItems"
                        key={pos}
                        // style={styles}
                        align='center'
                      >
                        {formatText(columnName, columnValue)}
                      </TableCell>
                    )
                  })}

                </TableRow>
              )
            })}

          </TableBody>

        </Table>
      </TableContainer>
    </div>
  )
}

export default OrderDetails;
