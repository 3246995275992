import React from 'react';
import PaymentSuccess from './../../components/PaymentSuccess';


const PaymentTrue = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <PaymentSuccess />
    </div>
  );
}

export default PaymentTrue;