import React from 'react';
import { useDispatch } from 'react-redux';
import { addProduct, reduceCartItem } from '../../../redux/Cart/cart.actions';
import './styles.scss';

const Quantity = (product) => {
  const dispatch = useDispatch();
  const {
    quantity,
  } = product;

  const handleAddProduct = (product) => {
    dispatch(
      addProduct(product)
    )
  }

  const handleReduceItem = (product) => {
    dispatch(
      reduceCartItem(product)
    );
  }

  return (
   
    <div>
      <span className="quantityText">
          <h4>
            Quantity:
          </h4> 
      </span>
   
      <div className="q-cartItem">
        <div className="q-minus" id="decrease" value="Decrease Value"
          onClick={() => handleReduceItem(product)}>
          -
        </div>
        <div className="q-number">
          {quantity}
        </div>
        <div className="q-plus" id="increase" value="Increase Value"
          onClick={() => handleAddProduct(product)}>
          +
        </div>
      </div>
    </div>
  );
}

export default Quantity;
