import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './styles.scss';


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const mapState = ({ productsData }) => ({
    products: productsData.products
  });

const Slides = () => {

    const { products } = useSelector(mapState);
    
    
    const [nav1, setNav1] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const { data } = products;


    useEffect(() => {
    
        window.scrollTo(0, 0)
        setNav1(slider1);
      }, [slider1]);
      
  
    const settingsMain = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.slider-nav',
        autoplay: true,
        speed: 10000,
        autoplaySpeed: 2000,
  
      };

      let slidesData = [];
      if(typeof data != 'undefined') {


        if(data.length > 4 ) {

            slidesData = [
                {
                    id: 1,
                    title: data[0].productName,
                    label: data[0].productName,
                    image: data[0].productThumbnail
                }, {
                    id: 2,
                    title: data[1].productName,
                    label: data[1].productName,
                    image: data[1].productThumbnail
                }, {
                    id: 3,
                    title: data[2].productName,
                    label: data[2].productName,
                    image: data[2].productThumbnail
                }, {
                    id: 4,
                    title: data[3].productName,
                    label: data[3].productName,
                    image: data[3].productThumbnail
                }, {
                    id: 5,
                    title: data[4].productName,
                    label: data[4].productName,
                    image: data[4].productThumbnail
                },
            ]

        } else {
            slidesData = [
                {
                    id: 1,
                    title: data[0].productName,
                    label: data[0].productName,
                    image: data[0].productThumbnail
                }, {
                    id: 2,
                    title: data[1].productName,
                    label: data[1].productName,
                    image: data[1].productThumbnail
                }, {
                    id: 3,
                    title: data[2].productName,
                    label: data[2].productName,
                    image: data[2].productThumbnail
                }, {
                    id: 4,
                    title: data[3].productName,
                    label: data[3].productName,
                    image: data[3].productThumbnail
                }
            ]
        }

        
        console.log("data",data[2].productThumbnail)

      }

        const updatedSlidesData = slidesData.filter(
        
            image => image.image !== ''
        
        );


      return (

        <div className="SlidesContainer">
            <div className="App App2">

                <h3>
                    You May also like
                </h3>
            
                <div className="slider-wrapper slider-wrapper2" >
                    <Link to="/search/dresses">
                            <Slider
                                {...settingsMain}
                                asNavFor={nav1}
                                ref={slider => (setSlider1(slider))}>
                
                                {updatedSlidesData.map((slide) =>
                                        <div className="slick-slide" key={slide.id}>
                                            <img className="slick-slide-image" src={slide.image} alt={slide.title} />
                                        </div>
                                )}
                            </Slider>
                    </Link>
                </div>
            </div>
        </div>
    );
}
export default Slides;